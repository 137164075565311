import { Icon, type Size } from '@biogroup/icons'
import { cn } from '@biogroup/utils/cn'

export function Loader({
  className,
  size = 'md',
}: {
  className?: string
  size?: Size
}) {
  return (
    <Icon name="loader" className={cn('animate-spin', className)} size={size} />
  )
}
