import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@biogroup/utils/cn'

const badgeVariants = cva(
  cn(
    'inline-flex items-center rounded-full px-2',
    'text-xs text-neutral-0 font-semibold',
    'focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  ),
  {
    variants: {
      variant: {
        default: 'bg-background text-headline border border-faded',
        success: 'bg-feedback-success',
        warning: 'bg-feedback-warning',
        error: 'bg-feedback-error',
        unclear: 'bg-dull text-muted-foreground',
        info: 'bg-info',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export type BadgeVariantProps = VariantProps<typeof badgeVariants>

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    BadgeVariantProps {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, children, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          badgeVariants({ variant }),
          { 'h-3 w-3 p-0': !children },
          { 'px-2 py-0': typeof children === 'string' && children.length > 1 },
          className,
        )}
      >
        {children}
      </div>
    )
  },
)

Badge.displayName = 'Badge'

export { Badge, badgeVariants }
