import * as React from 'react'

import { cn } from '@biogroup/utils/cn'

interface GridProps {
  children: React.ReactNode
  overflow?: boolean
  className?: string
  as?: React.ElementType
  id?: string
  nested?: boolean
  rowGap?: boolean
}

export const Grid = React.forwardRef<HTMLElement, GridProps>(function Grid(
  { children, className, as: Tag = 'div', nested, rowGap, id },
  ref,
) {
  return (
    <Tag
      ref={ref}
      id={id}
      className={cn('relative', {
        'w-full': nested,
      })}
    >
      <div
        className={cn(
          'relative grid grid-cols-4 gap-x-4 md:grid-cols-8 lg:grid-cols-12',
          {
            'mx-auto px-4 container:max-w-[1232px] container:!px-0': !nested,
            'gap-y-4': rowGap,
          },
          className,
        )}
      >
        {children}
      </div>
    </Tag>
  )
})
