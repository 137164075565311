export enum ErrorCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableContent = 422,
  ToManyRequests = 429,
  Unknown = 500,
}

export function isErrorCode(code: unknown): code is ErrorCode {
  return typeof code === 'number' && Object.values(ErrorCode).includes(code)
}
