import { Link, useLocation } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@biogroup/icons'
import { Badge } from '@biogroup/ui/badge'
import { Button } from '@biogroup/ui/button'
import { Grid } from '@biogroup/ui/grid'

import { ErrorCode } from '#app/utils/error.ts'
import { getErrorMessage } from '#app/utils/misc.ts'

import { StatusHandler } from './error-boundary.tsx'

export const fallbackErrorLabels: Record<string, Record<string, string>> = {
  notFound: {
    fr: 'Page non trouvée',
    en: 'Page not found',
  },
}

function ErrorPage({
  to,
  labels,
}: {
  to: string
  labels: {
    title: string
    subtitle: string
    badge?: string
    cta: string
  }
}) {
  return (
    <>
      <img
        src="/images/404.png"
        alt=""
        className="absolute inset-0 m-auto w-full max-w-[1064px]"
      />
      <Grid>
        <div className="bg-background/10 relative z-10 col-span-full rounded-lg p-8 shadow-lg backdrop-blur-lg lg:col-span-6 lg:col-start-4">
          {labels.badge ? (
            <Badge variant="unclear" className="mb-3">
              {labels.badge}
            </Badge>
          ) : null}
          <h1 className="mb-3 text-h3">{labels.title}</h1>
          <p className="mb-8 text-md text-muted-foreground">
            {labels.subtitle}
          </p>
          <Button asChild>
            <Link to={to} prefetch="intent" className="no-underline">
              <Icon name="arrow-left" size="md" />
              {labels.cta}
            </Link>
          </Button>
        </div>
      </Grid>
    </>
  )
}

export function NotFoundError() {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <ErrorPage
      to="/"
      labels={{
        title: t('404.title'),
        subtitle: t('404.subtitle', { path: location.pathname }),
        badge: t('404.badge'),
        cta: t('404.cta'),
      }}
    />
  )
}

export function ServerError() {
  const { t } = useTranslation()

  return (
    <ErrorPage
      to="/"
      labels={{
        title: t('500.title'),
        subtitle: t('500.subtitle'),
        badge: t('500.badge'),
        cta: t('500.cta'),
      }}
    />
  )
}

export function ForbiddenError() {
  const { t } = useTranslation()

  return (
    <ErrorPage
      to="/"
      labels={{
        title: t('403.title'),
        subtitle: t('403.subtitle'),
        cta: t('403.cta'),
      }}
    />
  )
}

export function UnexpectedError({ error }: { error: unknown }) {
  const { t } = useTranslation()

  if (typeof document !== 'undefined') {
    console.error(getErrorMessage(error))
  }

  return (
    <ErrorPage
      to="/"
      labels={{
        title: t('500.title'),
        subtitle: t('500.subtitle'),
        badge: t('500.badge'),
        cta: t('500.cta'),
      }}
    />
  )
}

export const statusHandlers: Record<ErrorCode, StatusHandler> = {
  [ErrorCode.BadRequest]: ServerError,
  [ErrorCode.Unauthorized]: ForbiddenError,
  [ErrorCode.Forbidden]: ForbiddenError,
  [ErrorCode.NotFound]: NotFoundError,
  [ErrorCode.UnprocessableContent]: ServerError,
  [ErrorCode.ToManyRequests]: ServerError,
  [ErrorCode.Unknown]: ServerError,
}
