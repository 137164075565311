// TODO: some of these helpers should probably move to the utils package when multiple remix apps are being build in this repo
import { useEffect, useState } from 'react'

import { useFormAction, useNavigation } from '@remix-run/react'
import { formatISO } from 'date-fns/formatISO'
import { useSpinDelay } from 'spin-delay'
import { type ZodError } from 'zod'

export function getErrorMessage(error: unknown) {
  if (typeof error === 'string') return error
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message
  }
  console.error('Unable to get error message for error', error)
  return 'Unknown Error'
}

export function formatZodError(error: ZodError | undefined) {
  if (!error) return 'No zod errors'
  return error?.errors
    .map(e => `'${e.path}': [${e.code}] ${e.message}`)
    .join(', ')
}

export function getDomainUrl(request: Request) {
  const host = request.headers.get('host') ?? new URL(request.url).host
  const protocol = host.includes('localhost') ? 'http' : 'https'
  return `${protocol}://${host}`
}

export function round(num: number) {
  return Math.round(num * 1e2) / 1e2
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }
  return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
  ...responseInits: Array<ResponseInit | null | undefined>
) {
  let combined: ResponseInit = {}
  for (const responseInit of responseInits) {
    combined = {
      ...responseInit,
      headers: combineHeaders(combined.headers, responseInit?.headers),
    }
  }
  return combined
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  return (...args: Args) => fns.forEach(fn => fn?.(...args))
}

/**
 * NOTE: This hook is not used currently, I've added it as a example to write some more complex unit tests
 *
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = useState(false)

  function getButtonProps(
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ) {
    const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
      () => setDoubleCheck(false)

    const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
      doubleCheck
        ? undefined
        : e => {
            e.preventDefault()
            setDoubleCheck(true)
          }

    const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] =
      e => {
        if (e.key === 'Escape') {
          setDoubleCheck(false)
        }
      }

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
      onKeyUp: callAll(onKeyUp, props?.onKeyUp),
    }
  }

  return { doubleCheck, getButtonProps }
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
  const contextualFormAction = useFormAction()
  const navigation = useNavigation()
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  )
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
  formAction,
  formMethod,
  delay = 400,
  minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
  Parameters<typeof useSpinDelay>[1] = {}) {
  const isPending = useIsPending({ formAction, formMethod })
  const delayedIsPending = useSpinDelay(isPending, {
    delay,
    minDuration,
  })
  return delayedIsPending
}

/**
 * Converts a string to title case, capitalizing the first letter of each word.
 * This function is Unicode-aware and correctly handles special characters (e.g., French accents).
 */
export function toTitleCase(str: string): string {
  const lowerCasedStr = str.toLocaleLowerCase()
  const titleCasedStr = lowerCasedStr.replace(
    /(^|\P{L})(\p{L})/gu,
    (_, p1, p2) => {
      return p1 + p2.toLocaleUpperCase()
    },
  )
  return titleCasedStr
}

export function useUpdateQueryStringValueWithoutNavigation(
  queryKey: string,
  queryValue: string,
) {
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const oldQuery = currentSearchParams.get(queryKey) ?? ''
    if (queryValue === oldQuery) return

    if (queryValue) {
      currentSearchParams.set(queryKey, queryValue)
    } else {
      currentSearchParams.delete(queryKey)
    }
    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join('?')
    // Normally with remix, you'd update the params via useSearchParams from react-router-dom
    // and updating the search params will trigger the search to update for you.
    // However, it also triggers a navigation to the new url, which will trigger
    // the loader to run which we do not want because all our data is already
    // on the client and we're just doing client-side filtering of data we
    // already have. So we manually call `window.history.pushState` to avoid
    // the router from triggering the loader.
    window.history.replaceState(null, '', newUrl)
  }, [queryKey, queryValue])
}

export function formatDateOfBirthToISO(dateOfBirth: string) {
  const reversed = dateOfBirth.split('/').reverse().join('/')
  return formatISO(new Date(reversed), {
    representation: 'date',
  })
}
